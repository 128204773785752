<template>
	<div class="material-input__component" :class="computedClasses">
		<select
			:id="id"
			v-model="valueCopy"
			class="material-input material-input__select"
			:name="name"
			:required="required"
			@focus="handleFocus(true)"
			@blur="handleFocus(false)"
			@input="handleModelInput"
		>
			<slot />
		</select>

		<span class="material-input-bar" />

		<label class="material-label">
			{{ label }}
		</label>

		<div v-if="error" class="material-errors">
			<FormError :error="error" class="material-error" />
		</div>
	</div>
</template>

<script>
import FormError from '@/shared/forms/FormError';

export default {
	name: 'MaterialSelect',
	components: { FormError },
	props: {
		id: {
			type: String,
			default: null,
		},
		name: {
			type: String,
			default: null,
		},
		value: {
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: true,
		},
		error: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			valueCopy: null,
			focus: false,
			valid: true,
		};
	},
	computed: {
		computedClasses() {
			return {
				'material--active': this.focus,
				'material--disabled': this.disabled,
				'material--has-errors': Boolean(!this.valid || this.error),
				'material--raised': Boolean(
					this.focus ||
						this.valueCopy || // has value
						(this.placeholder && !this.valueCopy)
				), // has placeholder
			};
		},
	},
	watch: {
		value(newValue) {
			// This watch works from the code side of the 2-way-binding:
			this.copyValue(newValue);
		},
	},
	beforeMount() {
		// Here we are following the Vue2 convention on custom v-model:
		// https://github.com/vuejs/vue/issues/2873#issuecomment-223759341
		this.copyValue(this.value);
	},
	methods: {
		handleModelInput(event) {
			this.$emit('input', event.target.value, event);
		},
		handleFocus(focused) {
			this.focus = focused;
		},
		copyValue(value) {
			this.valueCopy = value;
		},
		togglePasswordVisibility() {
			this.passwordVisible = !this.passwordVisible;
		},
	},
};
</script>
